'use strrict'

$(function() {
	// Language
	$('#form-language .language-select').on('click', function(e) {
		e.preventDefault()

		$('#form-language input[name=\'code\']').val($(this).attr('name'))

		$('#form-language').submit()
	})

  // Search
	$('#search input[name=\'search\']').parent().find('button').on('click', function() {
		var url = $('base').attr('href') + 'index.php?route=product/search'

		var value = $('header #search input[name=\'search\']').val()

		if (value) {
			url += '&search=' + encodeURIComponent(value)
		}

		location = url
	})

	$('#search input[name=\'search\']').on('keydown', function(e) {
		if (e.keyCode == 13) {
			$('header #search input[name=\'search\']').parent().find('button').trigger('click')
		}
	})
})

// Открытие/Закрытие мобильного навигационного меню
function mobileNavigationToggle() {
  $(this).toggleClass('open')
  setTimeout(
    () => $('#navbarMobile').toggleClass('open'),
  200)

  $('.overlay').css('right', '')
  $('body').css('paddingRight', '').removeClass('overflow-hidden')

  if ($(this).hasClass('open')) {
    let scrollBarWidth = $(window).outerWidth() - $(window).width()

    $('.overlay').fadeIn('fast').css('right', scrollBarWidth)
    $('body').addClass('overflow-hidden').css('paddingRight', scrollBarWidth)
  } else {
    setTimeout(() => $('.overlay').fadeOut('fast'), 200)
  }
}

// Скрытие мобильного навигационного меню
function mobileNavigationHide() {
  $('[data-toggle="navbar"]').removeClass('open')
  $('#navbarMobile').removeClass('open')
  $('.overlay').css('right', '').fadeOut('fast')

  $('body').css('paddingRight', '').removeClass('overflow-hidden')
}

// Cart add remove functions
var cart = {
	'change': function(el, key) {
    let qtyEl = $(el),
        qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10);

    qtyVal = qtyVal > 1 && !Number.isNaN(qtyVal) ? qtyVal : 1;

		this.update(key, qtyVal);
	},
	'plus': function(el, key) {
    let qtyEl = $(el).closest('.cart-quantity').find('input[name^="quantity"]'),
        qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10);

    qtyVal = !Number.isNaN(qtyVal) ? ++qtyVal : 1;

    this.update(key, qtyVal);
	},
	'minus': function(el, key) {
    let qtyEl = $(el).closest('.cart-quantity').find('input[name^="quantity"]'),
        qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10);

		qtyVal = qtyVal > 1 && !Number.isNaN(qtyVal) ? --qtyVal : 1;

    this.update(key, qtyVal);
	},
	'add': function(product_id, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/add',
			type: 'post',
			data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			dataType: 'json',
			success: function(json) {
				$('.alert-dismissible, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
          toastr.success(json['success']);

          $('#cartTotal').html(json['total']);

          $('#modalHeaderCart .modal-header-cart').load('index.php?route=common/cart/info .modal-header-cart > div');
        }
			}
		});
	},
	'update': function(key, quantity) {
		const initHeight = $('#modalHeaderCart .modal-header-cart .modal-body').outerHeight();
		$('#modalHeaderCart .modal-header-cart .modal-body').css('height', initHeight);
		
		$.ajax({
			url: 'index.php?route=checkout/cart/edit',
			type: 'post',
      data: 'quantity[' + key + ']=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			dataType: 'json',
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('#cartTotal').html(json['total']);
				}, 100);

				const newHeight = $('#modalHeaderCart .modal-header-cart .modal-body').outerHeight();
				$('#modalHeaderCart .modal-header-cart .modal-body').css('height', newHeight);

				$('#modalHeaderCart #cartBody').load('index.php?route=common/cart/info #cartBody > li');
			}
		});
	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('#cartTotal').html(json['total']);
				}, 100);

				$('#modalHeaderCart .modal-header-cart').load('index.php?route=common/cart/info .modal-header-cart > div');
			}
		});
	}
}